/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/**
 * Starter File: 
 * /resources/vendor/metronic/theme/sass/bootstrap.scss
 * 
 * Customized version of Metronic's Bootstrap file to only pull in required components.
 * 
 * If you need to customize a file, use the same directory structure as the 
 * parent Metronic theme and place custom sass files in
 * /resources/assets/metronic_whrc/sass/bootstrap
 */

/**
 * Core variables and mixins
 * ----------------------------------------------------------------------------
 */

@import "../../../vendor/metronic/theme/sass/bootstrap/variables";
@import "../../../vendor/metronic/theme/sass/bootstrap/mixins";

/**
 * Override bootstrap variables to match Metronic style
 * ----------------------------------------------------------------------------
 */

@import "../../../vendor/metronic/theme/sass/global/variables";
@import "../../../vendor/metronic/theme/sass/global/bootstrap-override";

/**
 * Reset and dependencies
 * ----------------------------------------------------------------------------
 */

@import "../../../vendor/metronic/theme/sass/bootstrap/normalize";
@import "../../../vendor/metronic/theme/sass/bootstrap/print";
@import "bootstrap/glyphicons";

/**
 * Core CSS
 * ----------------------------------------------------------------------------
 */

@import "../../../vendor/metronic/theme/sass/bootstrap/scaffolding";
@import "../../../vendor/metronic/theme/sass/bootstrap/type";
// @import "../../../vendor/metronic/theme/sass/bootstrap/code";
@import "../../../vendor/metronic/theme/sass/bootstrap/grid";
@import "../../../vendor/metronic/theme/sass/bootstrap/tables";
@import "../../../vendor/metronic/theme/sass/bootstrap/forms";
@import "../../../vendor/metronic/theme/sass/bootstrap/buttons";

/**
 * Components
 * ----------------------------------------------------------------------------
 */

// @import "../../../vendor/metronic/theme/sass/bootstrap/component-animations";
@import "../../../vendor/metronic/theme/sass/bootstrap/dropdowns";
@import "../../../vendor/metronic/theme/sass/bootstrap/button-groups";
@import "../../../vendor/metronic/theme/sass/bootstrap/input-groups";
@import "../../../vendor/metronic/theme/sass/bootstrap/navs";
@import "../../../vendor/metronic/theme/sass/bootstrap/navbar";
// @import "../../../vendor/metronic/theme/sass/bootstrap/breadcrumbs";
// @import "../../../vendor/metronic/theme/sass/bootstrap/pagination";
// @import "../../../vendor/metronic/theme/sass/bootstrap/pager";
// @import "../../../vendor/metronic/theme/sass/bootstrap/labels";
@import "../../../vendor/metronic/theme/sass/bootstrap/badges";
// @import "../../../vendor/metronic/theme/sass/bootstrap/jumbotron";
@import "../../../vendor/metronic/theme/sass/bootstrap/thumbnails";
@import "../../../vendor/metronic/theme/sass/bootstrap/alerts";
@import "../../../vendor/metronic/theme/sass/bootstrap/progress-bars";
// @import "../../../vendor/metronic/theme/sass/bootstrap/media";
// @import "../../../vendor/metronic/theme/sass/bootstrap/list-group";
// @import "../../../vendor/metronic/theme/sass/bootstrap/panels";
// @import "../../../vendor/metronic/theme/sass/bootstrap/responsive-embed";
@import "../../../vendor/metronic/theme/sass/bootstrap/wells";
// @import "../../../vendor/metronic/theme/sass/bootstrap/close";

/**
 * Components w/ JavaScript
 * ----------------------------------------------------------------------------
 */

// @import "../../../vendor/metronic/theme/sass/bootstrap/modals";
@import "../../../vendor/metronic/theme/sass/bootstrap/tooltip";
// @import "../../../vendor/metronic/theme/sass/bootstrap/popovers";
// @import "../../../vendor/metronic/theme/sass/bootstrap/carousel";

/**
 * Utility classes
 * ----------------------------------------------------------------------------
 */

@import "../../../vendor/metronic/theme/sass/bootstrap/utilities";
@import "../../../vendor/metronic/theme/sass/bootstrap/responsive-utilities";
